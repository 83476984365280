
interface VsrgComposerIconProps {
    className?: string
}

export function VsrgComposerIcon({ className }: VsrgComposerIconProps) {
    return <svg width="1em" height="1em" viewBox="0 0 192 177" className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M71 129C71 141.15 61.1503 151 49 151C36.8497 151 27 141.15 27 129C27 116.85 36.8497 107 49 107C61.1503 107 71 116.85 71 129Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7385 0H175.262C184.506 0 192 7.36161 192 16.4426V160.557C192 169.638 184.506 177 175.262 177H16.7385C7.49406 177 0 169.638 0 160.557V16.4426C0 7.36161 7.49407 0 16.7385 0ZM15.7538 75.9262V20.3115C15.7538 17.6406 17.958 15.4754 20.6769 15.4754H171.323C174.042 15.4754 176.246 17.6406 176.246 20.3115V75.9262C176.246 78.5971 174.042 80.7623 171.323 80.7623H20.6769C17.958 80.7623 15.7538 78.5971 15.7538 75.9262ZM171.323 96.2377H20.6769C17.958 96.2377 15.7538 98.4029 15.7538 101.074V156.689C15.7538 159.359 17.958 161.525 20.6769 161.525H171.323C174.042 161.525 176.246 159.359 176.246 156.689V101.074C176.246 98.4029 174.042 96.2377 171.323 96.2377Z" />
    <path d="M167 46.5C167 58.6503 157.15 68.5 145 68.5C132.85 68.5 123 58.6503 123 46.5C123 34.3497 132.85 24.5 145 24.5C157.15 24.5 167 34.3497 167 46.5Z" />
    </svg>
}