

interface VsrgIconprops {
    className?: string
}
export function VsrgIcon({ className }: VsrgIconprops) {
    return <svg 
        width="1em" height="1em" viewBox="0 0 222 211" fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg">
        <path d="M190 176C190 191.464 177.464 204 162 204C146.536 204 134 191.464 134 176C134 160.536 146.536 148 162 148C177.464 148 190 160.536 190 176Z"  />
        <path d="M32.4832 178.489C31.1118 177.118 31.1118 174.894 32.4832 173.523L63.5229 142.483C64.8943 141.112 67.1179 141.112 68.4893 142.483L99.529 173.523C100.9 174.894 100.9 177.118 99.529 178.489L68.4893 209.529C67.1179 210.9 64.8943 210.9 63.5229 209.529L32.4832 178.489Z"  />
        <path d="M41 3.51175C41 1.57226 42.5723 0 44.5117 0H88.4086C90.3481 0 91.9203 1.57226 91.9203 3.51175V47.4086C91.9203 49.3481 90.3481 50.9203 88.4086 50.9203H44.5117C42.5723 50.9203 41 49.3481 41 47.4086V3.51175Z"  />
        <path d="M49 36C49 34.8954 49.8954 34 51 34H81C82.1046 34 83 34.8954 83 36V162C83 163.105 82.1046 164 81 164H51C49.8954 164 49 163.105 49 162V36Z"  />
        <path d="M33.551 160.54C34.0997 159.957 33.6863 159 32.8858 159H17C7.61116 159 0 166.611 0 176C0 185.389 7.61116 193 17 193H32.8858C33.6863 193 34.0997 192.043 33.551 191.46L19.6451 176.685C19.2827 176.3 19.2827 175.7 19.6451 175.315L33.551 160.54Z"  />
        <path d="M98.5862 160.685C97.9856 160.047 98.4381 159 99.3144 159H128.338C129.064 159 129.508 159.796 129.127 160.414C123.226 169.967 123.226 182.033 129.127 191.586C129.508 192.204 129.064 193 128.338 193H99.3144C98.4381 193 97.9856 191.953 98.5862 191.315L112.355 176.685C112.717 176.3 112.717 175.7 112.355 175.315L98.5862 160.685Z"  />
        <path d="M221.5 176C221.5 166.611 213.889 159 204.5 159H195.662C194.936 159 194.492 159.796 194.873 160.414C200.774 169.967 200.774 182.033 194.873 191.586C194.492 192.204 194.936 193 195.662 193H204.5C213.889 193 221.5 185.389 221.5 176Z"  />
    </svg>

}