import { SvgNoteImageProps } from "..";

export default function faNote({style}: SvgNoteImageProps) {
    return <svg
		style={style}
		className={'svg-note'}
        viewBox="0 0 311.9 311.9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            style={{
                strokeWidth: 6
            }}
            d="m 226.7,202.72 h -37.58 c 16.8363,-9.58302 27.35494,-27.34986 27.66,-46.72 -0.29956,-19.38469 -10.81729,-37.16901 -27.66,-46.77 l 37.83786,-0.0688 c 4.7358,-0.13246 6.4658,-2.47976 6.48777,-4.37967 -0.033,-3.70432 -3.03137,-5.63043 -6.73563,-5.59158 L 85.181367,99.165694 c -4.426124,-0.04652 -6.759199,2.911456 -6.775673,4.809776 0.03299,3.70037 3.083938,5.28782 6.784306,5.25453 h 37.59 c -16.84564,9.59801 -27.364464,27.38418 -27.66,46.77 0.290998,19.3845 10.81153,37.16976 27.66,46.76 l -38.011842,0.0972 c -3.554356,-0.10833 -6.321655,2.78245 -6.338158,4.68393 0.03294,3.70274 3.067377,5.31327 6.77,5.26884 l 141.77505,-0.0344 c 3.58424,-0.0865 6.47845,-2.6101 6.49495,-4.50999 -0.0564,-2.82157 -2.33286,-5.57335 -6.77,-5.54564 z M 113.37,120.8 c 13.63,-16.56 43.81,-14.23 67.33,5.2 23.52,19.43 31.49,48.59 17.82,65.14 -13.67,16.55 -43.81,14.23 -67.34,-5.19 -23.53,-19.42 -31.49,-48.6 -17.81,-65.16 z"
        />
    </svg>
}