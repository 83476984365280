import { SvgNoteImageProps } from "..";

export default function tiNote({ style, background }: SvgNoteImageProps) {
    return <svg
        style={style}
        className={'svg-note'}
        viewBox="0 0 311.9 311.9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            className="svg-b"
            d="M10.6585 31.4143C14.0665 31.4143 16.8292 34.177 16.8292 37.585V113.54C16.8292 114.368 17.8162 114.816 18.4912 114.337C62.4503 83.1278 100.485 146.958 6.00837 199.981C5.34458 200.354 4.48779 199.895 4.48779 199.134V37.585C4.48779 34.177 7.2505 31.4143 10.6585 31.4143V31.4143ZM17.3901 131.108C17.3901 130.85 17.4993 130.592 17.6794 130.407C43.1371 104.333 68.853 142.392 18.9059 171.355C18.2453 171.738 17.3901 171.277 17.3901 170.513V131.108Z"
            style={{
                strokeWidth: 24,
                paintOrder: 'stroke',
                stroke: background
            }}
        />
        <path
            style={{
                strokeWidth: 6,
            }}
            d="m 216.53,149.37 10.64555,-0.0504 c 4.5708,-0.11496 6.27248,-2.88856 6.29445,-4.78612 -0.0385,-3.7004 -3.06961,-5.2423 -6.77,-5.20344 h -10.18 c -1.9694,-17.48661 -12.14801,-32.99034 -27.41,-41.75 h 38.28652 c 4.5374,-0.405759 6.02134,-3.035241 6.03782,-4.933555 C 233.39039,88.946804 230.3998,87.51221 226.7,87.54 L 84.646133,87.664824 c -4.647718,0.108052 -6.227366,2.727071 -6.216133,4.646528 0.03297,3.698792 3.061265,5.357498 6.76,5.318648 h 37.59 c -15.25929,8.76078 -25.434357,24.26476 -27.4,41.75 H 84.825323 c -4.319212,0.20475 -6.346244,2.57869 -6.362718,4.477 0.03297,3.70432 3.033137,5.60185 6.737395,5.563 h 10.16 c 1.968677,17.48157 12.14335,32.98124 27.4,41.74 l -38.009042,0.0357 c -4.128894,0.42916 -6.288797,2.75836 -6.305294,4.65825 0.04394,3.69964 3.034539,5.38388 6.734336,5.35609 l 141.5164,0.0252 c 5.50611,-0.18511 6.7571,-3.02321 6.7736,-4.9231 -0.0439,-3.69964 -3.0702,-5.17991 -6.77,-5.15212 h -37.58 c 15.27484,-8.76535 25.45587,-24.28761 27.41,-41.79 z M 108,139.33 c -3.06159,-10.26129 -1.05653,-21.36713 5.4,-29.91 13.67,-16.56 43.82,-14.23 67.33,5.19 8.25742,6.69841 14.93601,15.13543 19.56,24.71 H 108 Z m 23.25,35.23 c -8.38883,-6.80804 -15.14164,-15.41309 -19.76,-25.18 h 92.41 c 3.21387,10.3884 1.23814,21.68843 -5.31,30.37 -13.68,16.54 -43.83,14.22 -67.35,-5.18 z" />
        <path
            style={{
                strokeWidth: 6,
            }}
            d="m 226.7,224.32 -141.217602,0.0126 c -3.919227,-0.0677 -6.908324,2.57912 -6.924821,4.47901 0.03297,3.70432 2.128938,5.51633 6.642423,5.55843 h 141.54684 c 4.29339,-0.26627 6.54988,-2.72292 6.57185,-4.62048 -0.0439,-3.69964 -2.61627,-4.87729 -6.61869,-5.42952 z" />
    </svg>
}

