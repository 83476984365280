interface AddColumnProps{
    className?: string
}

export default function AddColumn({ className }: AddColumnProps) {
    return <svg
        width="194.40327mm"
        height="290.853mm"
        viewBox="0 0 194.40327 290.85299"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={{ fill: 'currentcolor' }}
    >
        <g>
            <rect
                width="50.962246"
                height="290.853"
                x="143.44104"
                y="2.4868996e-14"
                rx="15.05095"
                ry="17.061689" />
            <path d="m 42.968955,90.42652 c -2.198688,0 -3.96875,1.770063 -3.96875,3.96875 v 35.03145 H 3.9687499 C 1.7700625,129.42672 0,131.19678 0,133.39547 v 24.0621 c 0,2.19869 1.7700625,3.96875 3.9687499,3.96875 H 39.000205 v 35.03145 c 0,2.19869 1.770062,3.96875 3.96875,3.96875 h 24.062613 c 2.198687,0 3.968749,-1.77006 3.968749,-3.96875 v -35.03145 h 35.030933 c 2.19869,0 3.96875,-1.77006 3.96875,-3.96875 v -24.0621 c 0,-2.19869 -1.77006,-3.96875 -3.96875,-3.96875 H 71.000317 V 94.39527 c 0,-2.198687 -1.770062,-3.96875 -3.968749,-3.96875 z" />
            <rect
                width="7.8557625"
                height="1.5711526"
                x="57.085205"
                y="139.30885"
                rx="3.96875" />
        </g>
    </svg>
}