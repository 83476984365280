import { SvgNoteImageProps } from "..";

export default function doNote({style}: SvgNoteImageProps) {
	return <svg
		style={style}
		className={'svg-note'}
		viewBox="0 0 311.9 311.9"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			style={{
				strokeWidth: 6
			}}
			d="m 226.69,150.93 h -10.17 c -4.19354,-29.95167 -30.35627,-51.882064 -60.58,-50.78 -30.22373,-1.102064 -56.386455,20.82833 -60.58,50.78 l -10.504617,0.0243 c -3.891382,0.0264 -6.394603,2.88715 -6.411077,4.78546 0.02751,3.7114 3.034299,5.28795 6.745694,5.26023 h 10.17 c 4.193545,29.95167 30.35627,51.88206 60.58,50.78 30.22373,1.10206 56.38646,-20.82833 60.58,-50.78 l 10.32017,0.0243 c 4.15875,-0.0993 6.61336,-2.81424 6.62983,-4.71255 -0.0164,-3.72003 -3.0602,-5.42621 -6.78,-5.38176 z M 113.36,120.82 c 13.67,-16.56 43.82,-14.22 67.33,5.2 8.33131,6.76474 15.04502,15.30636 19.65,25 H 108 c -3.14876,-10.34257 -1.15212,-21.56762 5.37,-30.19 z m 85.16,70.34 c -13.67,16.55 -43.82,14.22 -67.34,-5.2 -8.32624,-6.76973 -15.03898,-15.3101 -19.65,-25 h 92.36 c 3.12094,10.31692 1.12951,21.50388 -5.36,30.11 z"
		/>
	</svg>
}
