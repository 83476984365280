import { SvgNoteImageProps } from "..";

export default function labNote({ style, background }: SvgNoteImageProps) {
    return <svg
        style={style}
        className={'svg-note'}
        viewBox="0 0 311.9 311.9"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            className="svg-b"
            d="M10.6585 31.4143C14.0665 31.4143 16.8292 34.177 16.8292 37.585V113.54C16.8292 114.368 17.8162 114.816 18.4912 114.337C62.4503 83.1278 100.485 146.958 6.00837 199.981C5.34458 200.354 4.48779 199.895 4.48779 199.134V37.585C4.48779 34.177 7.2505 31.4143 10.6585 31.4143V31.4143ZM17.3901 131.108C17.3901 130.85 17.4993 130.592 17.6794 130.407C43.1371 104.333 68.853 142.392 18.9059 171.355C18.2453 171.738 17.3901 171.277 17.3901 170.513V131.108Z"
            style={{
                strokeWidth: 24,
                paintOrder: 'stroke',
                stroke: background
            }}
        />
        <path
            style={{
                strokeWidth: 6
            }}
            d="m 226.7,190.83 h -37.58 c 17.06075,-9.37792 27.66156,-27.3017 27.66156,-46.77 0,-19.4683 -10.60081,-37.39208 -27.66156,-46.77 l 38.16442,-0.103123 c 4.26786,0.01666 6.09486,-2.741511 6.11683,-4.637481 C 233.39173,89.915363 230.3998,87.22221 226.7,87.25 l -141.713085,0.07292 c -3.964301,0.0021 -6.540441,2.838539 -6.556915,4.736853 -0.04545,3.167971 3.05739,5.269074 6.76,5.230228 h 37.59 c -17.06075,9.37792 -27.661558,27.3017 -27.661558,46.77 0,19.4683 10.600808,37.39208 27.661558,46.77 l -38.032545,0.0687 c -3.964604,0.25279 -6.346493,2.47025 -6.357455,4.37248 0.04394,3.69964 3.070203,5.63656 6.77,5.60877 l 142.06907,-0.0486 c 3.82173,0.0111 6.22443,-2.53939 6.24093,-4.44087 -0.0385,-3.70039 -3.06961,-5.59938 -6.77,-5.56052 z M 113.37,108.9 c 13.67,-16.56 43.81,-14.23 67.34,5.19 23.53,19.42 31.49,48.59 17.82,65.14 -13.67,16.55 -43.82,14.23 -67.33,-5.23 -23.51,-19.46 -31.51,-48.55 -17.83,-65.1 z"
        />
        <path
            style={{
                strokeWidth: 6
            }}
            d="m 226.7,224.61 -142.95,0.05 c -3.046024,-0.16666 -5.248026,3.05403 -5.27,4.95 0.02747,3.70271 3.00739,5.07885 6.71,5.04 l 141.41589,-0.0354 c 4.22947,0.0167 6.84214,-2.60004 6.86411,-4.49601 -0.0439,-3.69964 -3.0702,-5.53642 -6.77,-5.50863 z"
        />
    </svg>
}